import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import config from "../global/config";
import { getEvents } from "../services/GraphService";
import Spinner from "./Spinner";

// Helper function to format Graph date/time
function formatDateTime(dateTime) {
  return moment
    .utc(dateTime)
    .local()
    .format("M/D/YY h:mm A");
}

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      loading: false
    };
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      // Get the user's access token
      var accessToken = await window.msal.acquireTokenSilent(config.scopes);

      // Get the user's events
      var events = await getEvents(accessToken);
      console.log("Events", events);
      // Update the array of events in state
      this.setState({ events: events.value, loading: false });
    } catch (err) {
      this.setState({ loading: false });
      this.props.showError("ERROR", JSON.stringify(err));
    }
  }

  render() {
    return (
      <div>
        <h3>My Calendar</h3>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <Table>
            <thead>
              <tr>
                <th scope="col">Subject</th>
                <th scope="col">Organizer</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
              </tr>
            </thead>
            <tbody>
              {this.state.events.map(function(event) {
                return (
                  <tr key={event.id}>
                    <td>{event.subject}</td>
                    <td>
                      {event.organizer.emailAddress.name === "Vishal Avalani"
                        ? "Saurav Jaiswal"
                        : event.organizer.emailAddress.name}
                    </td>
                    <td>{formatDateTime(event.start.dateTime)}</td>
                    <td>{formatDateTime(event.end.dateTime)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}
