import React from "react";
import { Button, Jumbotron } from "reactstrap";
import Dashboard from "./Dashboard";

function WelcomeContent(props) {
  // If authenticated, greet the user
  if (props.isAuthenticated) {
    return (
      <div>
        <h4>Welcome {props.user.displayName}!</h4>
        <p>Use the navigation bar at the top of the page to get started.</p>
      </div>
    );
  }

  // Not authenticated, present a sign in button
  return (
    <Button color="primary" onClick={props.authButtonMethod}>
      Click here to sign in
    </Button>
  );
}

export default class Welcome extends React.Component {
  render() {
    const { isAuthenticated, authButtonMethod, user, showError } = this.props;
    return (
      <>
        {!isAuthenticated ? (
          <Jumbotron>
            <h1>Graph App</h1>
            <p className="lead">
              This is a Graph App used to demonstrate the features that can be explored using MS Graph Api.
            </p>
            <WelcomeContent isAuthenticated={isAuthenticated} user={user} authButtonMethod={authButtonMethod} />
          </Jumbotron>
        ) : (
          <Dashboard user={user} showError={showError} />
        )}
      </>
    );
  }
}
