import React from "react";
import { Row, Col } from "reactstrap";
import Calendar from "./Calendar";
import RightMenu from "./RightMenu";
import Contacts from "./Contacts";
import Files from "./Files";

export default class Dashboard extends React.Component {
  render() {
    return (
      <Row>
        <Col xs="8">
          <Calendar {...this.props} />
          <Files {...this.props} />
          <Contacts {...this.props} />
        </Col>
        <Col xs="4">
          <RightMenu {...this.props} />
        </Col>
      </Row>
    );
  }
}
