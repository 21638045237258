var graph = require("@microsoft/microsoft-graph-client");

function getAuthenticatedClient(accessToken) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: done => {
      done(null, accessToken);
    }
  });

  return client;
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api("/me").get();
  return user;
}

export async function getEvents(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  const events = await client
    .api("/me/events")
    .select("subject,organizer,start,end")
    .orderby("createdDateTime DESC")
    .get();

  return events;
}

export async function getContacts(accessToken) {
  const client = getAuthenticatedClient(accessToken);
  const contacts = await client.api("/me/contacts").get();
  return contacts;
}

export async function getNews(accessToken) {
  const client = getAuthenticatedClient(accessToken);
  // innostrattech.sharepoint.com,8425c3c4-ad06-4504-832b-6037db87d38c,86f281fa-9c83-4dde-86b4-d0117f8d29ed
  const news = await client
    .api(
      "/sites/innostrattech.sharepoint.com,8425c3c4-ad06-4504-832b-6037db87d38c,86f281fa-9c83-4dde-86b4-d0117f8d29ed/lists/f1266bbf-f519-4b21-bc60-7b1a5dbe3f18/items?expand=fields"
    )
    .get();
  return news;
}

//me/drive  to get the drive files

export async function getDrive(accessToken) {
  const client = getAuthenticatedClient(accessToken);
  const drives = await client.api("/me/drive").get();
  return drives;
}

export async function getFiles(accessToken, driveId) {
  const client = getAuthenticatedClient(accessToken);
  const files = await client.api(`/drives/${driveId}/root/children`).get();
  // const files = await client.api(`/me/drive/items/01INN2ABV6Y2GOVW7725BZO354PWSELRRZ/children`).get();
  return files;
}
