import React from "react";
import { Table } from "reactstrap";
import config from "../global/config";
import { getContacts } from "../services/GraphService";
import Spinner from "./Spinner";

export default class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      loading: false
    };
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });

      // Get the user's access token
      var accessToken = await window.msal.acquireTokenSilent(config.scopes);
      // Get the user's contacts
      var contacts = await getContacts(accessToken);
      console.log("Contacts", contacts.value);
      // Update the array of contacts in state
      this.setState({ contacts: contacts.value, loading: false });
    } catch (err) {
      this.setState({ loading: false });
      console.log("Contacts Error", err);
      this.props.showError("ERROR", JSON.stringify(err));
    }
  }

  render() {
    return (
      <div>
        <h3>My Contacts</h3>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <Table>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Company</th>
                <th scope="col">Mobile</th>
              </tr>
            </thead>
            <tbody>
              {this.state.contacts.map(function(contact) {
                return (
                  <tr key={contact.id}>
                    <td>{contact.displayName}</td>
                    <td>{contact.companyName}</td>
                    <td>{contact.mobilePhone}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}
