import React from "react";
import Info from "./Info";
import config from "../global/config";
import { getNews } from "../services/GraphService";
import Spinner from "./Spinner";

export default class RightMenu extends React.Component {
  state = {
    news: [],
    loading: false
  };
  async componentDidMount() {
    try {
      this.setState({ loading: true });

      var accessToken = await window.msal.acquireTokenSilent(config.scopes);
      var news = await getNews(accessToken);
      console.log("news", news.value);
      this.setState({ news: news.value, loading: false });
    } catch (err) {
      this.setState({ loading: false });
      this.props.showError("ERROR", JSON.stringify(err));
    }
  }
  render() {
    console.log("RightMenu", this.props);
    const { displayName, email, jobTitle, mobilePhone } = this.props.user;
    return (
      <div style={{ borderLeft: "1px solid lightgray", height: "100%", paddingLeft: "20px" }}>
        <h3>My Information</h3>
        <Info title="Name" value={displayName} />
        <Info title="Title" value={jobTitle} />
        {/* <Info title="Email" value={email} /> */}
        <Info title="Phone" value={mobilePhone} />
        <br />
        <h3>SharePoint List - News</h3>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <ul>
            {this.state.news.map(item => {
              return (
                <li key={item.fields["id"]}>
                  <a rel="noopener noreferrer" target="_blank" href={item.fields["Url"]}>
                    {item.fields["Title"]}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
