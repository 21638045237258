import React from "react";

const Info = props => {
  const { title, value } = props;
  return (
    <div>
      <span>
        <strong>{title}: </strong>
        {value}
      </span>
    </div>
  );
};

export default Info;
